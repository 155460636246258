import { ContentContainer, PageContainer } from '@dayinsure/components';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { PolicyInfoButtons } from './PolicyInfoButtons';
import { PolicyDocuments } from '../../../components/PolicyDocuments';
import { PolicyInfoPayments } from './PolicyInfoPayments';
import { PolicyInfoCovers } from './PolicyInfoCovers';
import { PolicyInfoDrivers } from './PolicyInfoDrivers';
import { PolicyInfoCar } from './PolicyInfoCar';
import { PolicyInfoCover } from './PolicyInfoCover';
import { PolicyInfoDetails } from './PolicyInfoDetails';
import { usePolicyQuery, useMotorPoliciesSummaryQuery } from '../../../hooks';
import { ExcessesCard, Loader, ContactCards } from '../../../components';
import { ConditionsCard } from '../../../components/Quote';
import { FeesAndCharges } from '../../Quote/Review/FeesAndCharges';
import { PendingChangesModal } from './PolicyInfoPendingChanges';
import { PolicyInfoPanels } from './PolicyInfoPanels';

const testId = 'policy-info';

export const PolicyInfo = () => {
  const { id } = useParams<{ id: string }>();
  const { data: policy } = usePolicyQuery(id);
  const [showModal, setShowModal] = useState(false);
  const cantEdit =
    policy?.state === 'Expired' ||
    policy?.state === 'Cancelled' ||
    policy?.state === 'Voided';
  const { data: motorQuote } = useMotorPoliciesSummaryQuery();
  const policyDetails = motorQuote?.find(mq => mq.id === id);
  const pendingChanges = !!policy?.pendingChanges;

  const handleModalClose = () => {
    setShowModal(false);
  };

  if (!policy || !policyDetails) {
    return <Loader />;
  }

  // TO DO: mocks
  const policyMock = {
    payments: {
      type: 'Yearly',
      amount: '£948.99 /year',
      lastPayment: '£948.99 on 01/01/2023',
    },
  };

  return (
    <PageContainer className="font-raleway">
      <h1
        className="my-8 text-xl text-center md:my-12 lg:my-16 text-main-content-1"
        data-testid={`${testId}_title`}
      >
        Policy info
      </h1>
      <ContentContainer>
        {id && (
          <>
            <PolicyInfoPanels
              pendingChanges={policy?.pendingChanges}
              cancelledDateTimeUtc={policy?.cancelledDateTimeUtc}
              testId={testId}
            />

            <PolicyInfoDetails testId={testId} details={{ ...policyDetails, id }} />
            {policy?.vehicle?.registrationNumber && (
              <PolicyInfoCar
                testId={testId}
                vehicle={policy.vehicle}
              />
            )}
            {policyDetails.startDate && policyDetails.endDate && (
              <PolicyInfoCover
                testId={testId}
                startDate={policyDetails.startDate}
                endDate={policyDetails.endDate}
              />
            )}
            {policy.drivers?.length && policy.policyholder && (
              <PolicyInfoDrivers
                testId={testId}
                drivers={policy.drivers}
                policyHolder={policy.policyholder}
              />
            )}
            {policy.cover && (
              <PolicyInfoCovers
                testId={testId}
                cover={policy.cover}
                addons={policy.addOns}
              />
            )}
            {policy.totalPrice && (
              <PolicyInfoPayments
                testId={testId}
                payments={policyMock.payments}
                totalPrice={policy.totalPrice}
              />
            )}
            <ConditionsCard testId={testId} />
            <PolicyDocuments testId={testId} policyId={id} />
            <FeesAndCharges
              isPlannAnnual
              testId={testId}
              feesForDisplay={policy?.snapshotTransaction?.price?.feesForDisplay}
            />
            <ExcessesCard testId={testId} noEdit />
            <ContactCards className="mt-12" testId={testId} showLinks={false} />
            <PolicyInfoButtons
              testId={testId}
              cantEdit={cantEdit}
              plateNo={policy?.vehicle?.registrationNumber || undefined}
              showModal={setShowModal}
              pendingChanges={pendingChanges}
            />
          </>
        )}
      </ContentContainer>
      <PendingChangesModal
        isOpen={showModal}
        onClose={handleModalClose}
        testId={testId}
      />
    </PageContainer>
  );
};
